
require('@fullcalendar/core/main.min.css')
require('@fullcalendar/daygrid/main.min.css')
require('@fullcalendar/timegrid/main.min.css')

import Fullcalendar from '@fullcalendar/vue'
import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import InteractionPlugin from '@fullcalendar/interaction'
import ListPlugin from '@fullcalendar/list'
import EventModal from './EventModal'

import {mapGetters} from 'vuex'

import moment from 'moment'

import { required, minLength } from "vuelidate/lib/validators";

export default {
	name: 'Agenda',
	props:{
		exercice_comptables: Array,
		societe: [String,Number]
	},
	data () {
		return {
			calendarPlugins: [
				DayGridPlugin,
				TimeGridPlugin,
				InteractionPlugin,
				ListPlugin
			],
			event:{
				societe_id: this.societe,
				allDay: false,
				color: "#3788d8"
			},
			start_time: '12:00',
			end_time: '19:00',
			submitted: false,
		}
	},
	computed: mapGetters({
		events: 'agendaStoreIndex/EVENT',
	}),
	components:{
		Fullcalendar,
	},
	created(){
		this.$store.dispatch("agendaStoreIndex/AGENDA",this.societe)
	},
	methods:{
		updateEvent (arg) {
			console.log(arg.event)
			let event={}
			
			event.start =  moment(arg.event.start).format("YYYY-MM-DD HH:mm:ss")
			event.end = moment(arg.event.end).format("YYYY-MM-DD HH:mm:ss")
			event.title = arg.event.title
			event.color = arg.event.borderColor
			event.allDay = arg.event.allDay
			event.societe_id = this.societe
			event.id = arg.event.id

			this.$store.dispatch("agendaStoreIndex/UPDATE_EVENT", event)
		},
		AddEvent () {
			this.submitted=true

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}else{
				this.event.societe_id = this.societe
				this.event.start=moment(this.event.start+' '+this.start_time).format("YYYY-MM-DD HH:mm:ss")
				this.event.end=moment(this.event.end+' '+this.end_time).format("YYYY-MM-DD HH:mm:ss")
				this.$store.dispatch("agendaStoreIndex/ADD_EVENT",this.event)
				this.submitted=false
			}
		},
		handleEventClick (arg) {
			this.$modal.show(EventModal, {
				text: "This is from the component",
				event: arg.event
			})
		},
	},
	validations: {
		event: {
			title: {
				required,
				minLength: minLength(4)
			},
			start: {
				required
			},
			end: {
				required,
			},
			color: {
				required
			},
		}
	},
	watch:{
	}
}
